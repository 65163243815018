import React from 'react';
import { createPortal } from 'react-dom';

/*
 * Next specific code
 */

interface Props {
  children: React.ReactNode;
  selector: string;
}

const Portal: React.FC<Props> = ({ children, selector }) => {
  const portalRef = React.useRef<HTMLElement | null>(null);

  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    portalRef.current = document.querySelector(selector);
    setMounted(true);
  }, [selector]);

  return mounted && portalRef.current ? createPortal(children, portalRef.current) : null;
};

export default React.memo(Portal);
