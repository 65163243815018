import { requestIdleCallback } from 'next/dist/client/request-idle-callback';

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

function loadSentry() {
  import('@sentry/nextjs')
    .then((Sentry) => {
      Sentry.init({
        dsn: SENTRY_DSN || 'https://a1a02d8d566442b99c648fe9e489e9fa@o244378.ingest.sentry.io/5217610',
        tracesSampleRate: 1.0,
        environment: process.env.NEXT_PUBLIC_WEB_PATH.startsWith('https://paritynow.co') ? 'production' : 'staging',
      });
    })
    .catch();
}

// if (document.readyState === 'complete') {
//   requestIdleCallback(loadSentry);
// } else {
//   window.addEventListener('load', () => {
//     requestIdleCallback(loadSentry);
//   });
// }
