import usePassiveLayoutEffect from '@react-hook/passive-layout-effect';
import Router from 'next/router';
import useSWR, { mutate, SWRConfiguration } from 'swr';

import { RejectedResult } from 'lib/request/Result';
import { joinUrl } from 'lib/request/utils';
import { User } from 'types';

const key = '/users/me';

export const USER_PREFETCH_URL = joinUrl(key);

export const mutateUser = (user?: User): Promise<User> => mutate(key, user);

type UserConfig = SWRConfiguration<User | null>;

export default function useUser(config?: UserConfig) {
  const { data, error, ...response } = useSWR<User | null, RejectedResult>(key, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    ...config,
  });

  return {
    user: error?.status === 403 ? undefined : data,
    error,
    ...response,
  };
}

export function useAuthenticatedUser(config?: UserConfig) {
  const { user, ...data } = useUser(config);

  usePassiveLayoutEffect(() => {
    if (!user) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error('useAuthenticatedUser hook must be used in secured space.');
      }

      Router.replace('/signin');
    }
  }, [user]);

  return {
    ...data,
    user: user as User,
  };
}
