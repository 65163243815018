import { css } from '@emotion/react';
import styled from '@emotion/styled';

import typography from 'styles/typography';

export interface TextProps {
  variant?: keyof typeof typography;
  color?: string;
}

export default styled.p<TextProps>`
  ${(props) => typography[props.variant || 'body']};
  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `};
`;
