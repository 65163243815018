import { camelizeKeys } from 'humps';
import Cookie from 'js-cookie';
import getConfig from 'next/config';

import { checkWindow } from 'lib/browser';
import { ApiError, DjangoApiError } from 'types';

import { FulfilledResult, RejectedResult } from './Result';
import {
  extendHeaders,
  FormDataRequestConfig,
  formDataSerializer,
  isFormDataRequest,
  isGetMethod,
  joinUrl,
  JsonRequestConfig,
  jsonSerializer,
  RequestConfig,
  TIMEOUT_ERROR,
} from './utils';

async function requestFactory<R, E extends ApiError = ApiError>(endpoint: string, params: RequestConfig = {}) {
  const { timeout: timeoutTime, signal, rawResponse, ...init } = params;

  try {
    const response = await new Promise<Response>((resolve, reject) => {
      const abortController = checkWindow() ? new AbortController() : undefined;

      function abort() {
        if (!signal && abortController) {
          abortController.abort();
        }
      }

      const timeoutId = setTimeout(() => {
        abort();
        reject(new Error(TIMEOUT_ERROR));
      }, timeoutTime || 60000);

      const clear = () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };

      return fetch(joinUrl(endpoint), {
        ...init,
        signal: signal || abortController?.signal,
      }).then(
        (result) => {
          clear();
          resolve(result);
        },
        (result) => {
          clear();
          reject(result);
        }
      );
    });

    const { status, ok } = response;

    let data: unknown;

    if (ok || (!ok && !isGetMethod(params.method))) {
      try {
        const rawData = await response.json();
        if (rawResponse) {
          data = rawData;
        } else {
          data = camelizeKeys(rawData);
        }
      } catch {}
    }

    if (ok) {
      return new FulfilledResult({
        status,
        payload: data as R,
        swrKey: endpoint,
      });
    }

    return new RejectedResult({
      status,
      payload: data as E,
      swrKey: endpoint,
    });
  } catch (e) {
    return new RejectedResult({
      status: e instanceof Error && e.message === TIMEOUT_ERROR ? -1 : 0,
      payload: e as E,
      swrKey: endpoint,
    });
  }
}

export const matchError = <R, E extends ApiError = ApiError>(
  result: FulfilledResult<R> | RejectedResult<E> | unknown
): result is RejectedResult<E> => result instanceof RejectedResult;

export const unwrapResult = <R, E extends ApiError = ApiError>(result: FulfilledResult<R> | RejectedResult<E>) => {
  if (matchError(result)) {
    throw result;
  }

  return result;
};

export const matchApiError = (error: ApiError): error is DjangoApiError => !!error && !(error instanceof Error);

export type WrappedRequestConfig<B = any> = JsonRequestConfig<B> | FormDataRequestConfig;

/**
 * JSON Request
 * @param endpoint - string
 * @param config - JsonRequestConfig
 * @returns Promise<R>
 */
export default async function request<R, B = any, E extends ApiError = ApiError>(
  endpoint: string,
  config: WrappedRequestConfig<B> = {}
) {
  if (config.method) {
    config.method = config.method.toUpperCase();
  }

  if (checkWindow()) {
    const csrfToken = Cookie.get('csrftoken');

    if (!isGetMethod(config.method) && csrfToken) {
      config.headers = extendHeaders(config.headers, ['x-csrftoken', csrfToken]);
    }
  } else if (isGetMethod(config.method)) {
    config.headers = extendHeaders(config.headers, ['is-ssr', getConfig().serverRuntimeConfig.ssrKey]);
  }

  const init = isFormDataRequest(config) ? formDataSerializer(config) : jsonSerializer(config);

  return requestFactory<R, E>(endpoint, {
    credentials: 'include',
    referrerPolicy: 'strict-origin-when-cross-origin',
    ...init,
    body: init.body,
  });
}
