import React from 'react';
import { useTheme } from '@emotion/react';

import CloseWhiteIcon from 'assets/close-white.svg';
import AccessebilityLabel from 'components/AccessebilityLabel';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Portal from 'components/Portal';

import * as Styled from './styles';

type ShowMessageCallback = (message: React.ReactNode, title?: React.ReactNode, ms?: number) => void;

export interface AlertParams {
  onSuccess: ShowMessageCallback;
  onFailure: ShowMessageCallback;
}

interface AlertState {
  isOpen: boolean;
  isError: boolean;
  message: React.ReactNode;
  title: React.ReactNode;
}

interface Props {
  children: (params: AlertParams) => void;
}

const WithAlert = ({ children }: Props) => {
  const theme = useTheme();
  const iterationCount = React.useRef(0);
  const animationStarted = React.useRef(false);

  const [state, setState] = React.useState<AlertState>({
    isOpen: false,
    isError: false,
    message: '',
    title: '',
  });

  const close = () => {
    iterationCount.current = 0;
    animationStarted.current = false;

    setState({
      isOpen: false,
      isError: false,
      message: '',
      title: '',
    });
  };

  const onSuccess = (messageToShow: React.ReactNode, title?: React.ReactNode) => {
    setState({
      title: title || 'All set!',
      isOpen: true,
      isError: false,
      message: messageToShow,
    });
  };

  const onFailure = (messageToShow: React.ReactNode, title?: React.ReactNode) => {
    setState({
      title: title || 'Something went wrong!',
      isOpen: true,
      isError: true,
      message: messageToShow,
    });
  };

  return (
    <>
      {children ? children({ onSuccess, onFailure }) : null}
      {state.isOpen && (
        <Portal selector="#alert-root">
          <Styled.Container
            data-testID={`alert_${state.isError ? 'error' : 'success'}`}
            style={{
              backgroundColor: state.isError ? theme.status.error : theme.status.success,
            }}
            onAnimationStart={() => {
              animationStarted.current = true;
            }}
            onAnimationEnd={() => {
              animationStarted.current = false;
              iterationCount.current += 1;

              if (iterationCount.current === 2) {
                close();
              }
            }}
          >
            <Styled.Content>
              <Styled.Title variant="h3">{state.title}</Styled.Title>
              <Styled.Description>{state.message}</Styled.Description>
            </Styled.Content>
            <Button
              onClick={() => {
                if (!animationStarted.current) {
                  close();
                }
              }}
              size="medium"
            >
              <AccessebilityLabel>Close Alert</AccessebilityLabel>
              <Icon src={CloseWhiteIcon} alt="Close Icon" />
            </Button>
          </Styled.Container>
        </Portal>
      )}
    </>
  );
};

export default React.memo(WithAlert);
