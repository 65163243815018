import { css } from '@emotion/react';

export const MOBILE_SMALL = 424;
export const MOBILE = 768;
export const TABLET = 1024;
export const DESKTOP = 1240;

export const sizes = {
  mobileSmall: '424px',
  mobile: '768px',
  desktop: '1240px',
  layoutMaxWidth: '1240px',
  landingLayoutMaxWidth: '1440px',
  buttonHeightSmall: '40px',
  buttonHeightBig: '56px',
  inputHeight: '50px',
};

export const respondTo = {
  mobileSmall: `@media (max-width: ${MOBILE_SMALL}px)`,
  mobile: `@media (max-width: ${MOBILE}px)`,
  tablet: `@media (max-width: ${TABLET}px)`,
  desktop: `@media (max-width: ${DESKTOP}px)`,
  bp: (bp) => `@media (max-width: ${bp}px)`,
};

export const respondFrom = {
  mobileSmall: `@media (min-width: ${MOBILE_SMALL + 1}px)`,
  mobile: `@media (min-width: ${MOBILE + 1}px)`,
  tablet: `@media (min-width: ${TABLET + 1}px)`,
  bp: (bp) => `@media (min-width: ${bp + 1}px)`,
};

export const respondOnlyFor = {
  mobile: `@media (max-width: ${DESKTOP}px)`,
  desktop: `@media (min-width: ${DESKTOP}px)`,
};

export const lineClamp = (numLines, lineHeight) => css`
  overflow: hidden;
  word-break: break-all;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: ${numLines};
  -webkit-box-orient: vertical;
  max-height: ${numLines * lineHeight}px;
`;
