import 'modern-css-reset';
import 'react-phone-number-input/style.css';
import 'styles/modal.css';
import '@typeform/embed/build/css/widget.css';
import React from 'react';
import { css, Global, ThemeProvider } from '@emotion/react';
import usePassiveLayoutEffect from '@react-hook/passive-layout-effect';
import { NextComponentType, NextPageContext } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import Script from 'next/script';
import { DefaultSeo } from 'next-seo';
import NProgress from 'nprogress';
import { SWRConfig } from 'swr';

import ImageThumb from 'assets/parity_thumb.png';
import AlertContext from 'components/AlertContext';
import DeviceTypeContext from 'components/DeviceTypeContext';
import Footer from 'components/Footer';
import { Nav } from 'components/Nav';
import ResizeContextProvider from 'components/ResizeContext';
import WithAlert from 'components/WithAlert';
import { fetcher } from 'data/defaultFetcher';
import { matchError } from 'lib/request';
import * as styles from 'styles';
import theme from 'styles/theme';
import { ComponentStatics } from 'types';
import { UaDevice } from 'types/enums';

import { NavBrands } from '../components/NavBrands';

interface Props extends AppProps {
  Component: NextComponentType<NextPageContext, any, {}> & ComponentStatics;
  err?: any;
  store: any;
}

const isBrandsApp = !!process.env.NEXT_PUBLIC_BRANDS_APP;

const startEvent = () => {
  NProgress.start();
};

const doneEvent = () => {
  NProgress.done();
};

const onErrorRetry = (error, _, config, revalidate, opts) => {
  if (!matchError(error)) {
    return;
  }

  if (!config.isVisible()) {
    return;
  }

  if (typeof config.errorRetryCount === 'number' && opts.retryCount > config.errorRetryCount) {
    return;
  }

  if (error.status === -1 || error.status >= 500) {
    const count = Math.min(opts.retryCount, 8);
    const timeout = ~~((Math.random() + 0.5) * (1 << count)) * config.errorRetryInterval;

    setTimeout(revalidate, timeout, opts);
  }
};

const MyApp = ({ Component, pageProps, err, router }: Props) => {
  usePassiveLayoutEffect(() => {
    NProgress.configure({ showSpinner: false });

    const changeComplete = () => {
      if (window.location.href.indexOf('/set-up/')) {
        window.scrollTo(0, 0);
      }

      if (!('staticPage' in Component)) {
        doneEvent();
      }
    };

    Router.events.on('routeChangeStart', startEvent);
    Router.events.on('routeChangeComplete', changeComplete);
    Router.events.on('routeChangeError', doneEvent);

    return () => {
      Router.events.off('routeChangeStart', startEvent);
      Router.events.off('routeChangeComplete', changeComplete);
      Router.events.off('routeChangeError', doneEvent);
    };
  }, [Component]);

  const { cache, dt, ...props } = pageProps || {};

  const providerValue = React.useMemo(() => {
    const defaultConfig = {
      fetcher,
      onErrorRetry,
    };

    if (cache) {
      return {
        ...defaultConfig,
        fallback: Object.fromEntries(
          Object.keys(cache).map((key) => {
            if (typeof props[key] === 'string') {
              return [key, props[key]];
            }

            return [key, cache[key]];
          })
        ),
      };
    }

    return defaultConfig;
  }, [cache]);

  const apiUrl = new URL(process.env.NEXT_PUBLIC_API_PATH).origin;

  return (
    <SWRConfig value={providerValue}>
      <Head>
        <link rel="dns-prefetch" href={apiUrl} />
        <link rel="preconnect" href={apiUrl} />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#131734" />
        <meta name="msapplication-TileColor" content="#131734" />
        <meta name="theme-color" content="#131734" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Parity" />
        <meta property="og:url" content="https://paritynow.co/" />
        <meta property="og:description" content="Join the movement for parity in women’s sports." />
        <meta property="og:image" content={ImageThumb.src} />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap"
          rel="stylesheet"
        />
      </Head>
      <Script
        strategy="afterInteractive"
        type="text/javascript"
        id="hs-script-loader"
        src="https://js-na1.hs-scripts.com/20518965.js"
      />
      <Script src="/scripts/flexgap.js" strategy="afterInteractive" />
      {!process.env.NEXT_PUBLIC_TESTS && (
        <>
          {/*<Script id="load-segment" strategy="beforeInteractive" src="/scripts/segment.js" />*/}
          <Script
            src="https://app.termly.io/embed.min.js"
            data-auto-block="off"
            data-website-uuid="ee415ca9-2dcc-4dbd-b0ae-d897879fa1de"
            strategy="beforeInteractive"
          />
        </>
      )}
      <Global
        styles={css`
          html {
            font-family: ${theme.fonts.openSans};
          }

          body {
            margin: 0;
          }
          button,
          input {
            outline: none;
            border: none;
            padding: 0;
          }
          ul {
            margin: 0;
          }
          textarea {
            resize: none;
            outline: none;
            border: 0;
          }
          fieldset {
            border: none;
            margin: 0;
            padding: 0;
          }
          button {
            cursor: pointer;
            :disabled {
              cursor: not-allowed;
            }
          }
          a {
            color: pointer;
            text-decoration: none;
          }
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            font-weight: 800;
            margin: 0;
          }
          p {
            margin: 0;
          }
          ul {
            list-style: none;
            padding: 0;
          }
          #nprogress {
            pointer-events: none;
          }
          #nprogress .bar {
            background-color: ${theme.primary};
            position: fixed;
            z-index: 1031;
            top: 0;
            left: 0;
            width: 100%;
            height: 4px;
          }
          /* Flebox Gap polyfill */
          .no-flexbox-gap .flex-gap {
            --column-gap: var(--gap, 0px);
            --row-gap: var(--gap, 0px);
            margin: calc(var(--row-gap) / -2) calc(var(--column-gap) / -2);
          }

          .no-flexbox-gap .flex-gap > * {
            margin: calc(var(--row-gap) / 2) calc(var(--column-gap) / 2);
          }

          .flexbox-gap .flex-gap {
            gap: var(--gap, 0px);
          }

          /* Recaptcha. Reappears as global style in HOC. */
          .grecaptcha-badge {
            visibility: hidden;
          }

          @keyframes appear {
            0% {
              transform: scaleY(0);
              transform-origin: 0% 100%;
              opacity: 1;
            }
            100% {
              transform: scaleY(1), translateY(100%);
              transform-origin: 0% 100%;
              opacity: 1;
            }
          }

          #termly-code-snippet-support * {
            font-family: ${theme.fonts.openSans} !important;
          }
        `}
      />
      <DefaultSeo
        titleTemplate="%s | Parity"
        openGraph={{
          site_name: 'Parity',
          locale: 'en_US',
          type: 'website',
          title: 'Join the movement for parity',
          description: 'For pro female athletes only - join the movement for Parity in sports!',
        }}
      />
      <a
        id="skip-nav"
        className="screenreader-text"
        href="#main-content"
        css={css`
          position: absolute;
          left: -999px;
          width: 1px;
          height: 1px;
          top: auto;

          &:focus {
            color: #fff;
            display: inline-block;
            height: auto;
            width: auto;
            position: static;
            margin: auto;
          }
        `}
      >
        Skip Navigation or Skip to Content
      </a>
      <ThemeProvider theme={theme}>
        <DeviceTypeContext.Provider value={pageProps?.dt || UaDevice.Mobile}>
          <WithAlert>
            {(params) => (
              <AlertContext.Provider value={params}>
                <ResizeContextProvider>
                  {!Component.hideHeader && (isBrandsApp ? <NavBrands /> : <Nav />)}
                  <main
                    id="main-content"
                    css={css`
                      min-height: ${Component.hideHeader ? '100vh' : 'calc(100vh - 104px)'};
                      background-color: #fff;
                      display: flex;

                      ${styles.respondTo.mobile} {
                        min-height: auto;
                      }
                    `}
                  >
                    <Component {...pageProps} err={err} />
                  </main>
                  {!Component.hideFooter && <Footer />}
                  <div id="alert-root" />
                  <div id="modal-root" />
                </ResizeContextProvider>
              </AlertContext.Provider>
            )}
          </WithAlert>
        </DeviceTypeContext.Provider>
      </ThemeProvider>
    </SWRConfig>
  );
};

export default MyApp;
