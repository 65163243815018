import { decamelize, decamelizeKeys } from 'humps';

import { checkWindow } from 'lib/browser';

export const TIMEOUT_ERROR = 'timeout';

export const joinUrl = (endpoint: string) => {
  const baseUrl = new URL(process.env.NEXT_PUBLIC_API_PATH || '');
  const newUrl = new URL(endpoint, baseUrl);

  let url = new URL(`${baseUrl.pathname}${newUrl.pathname}`, baseUrl.origin).toString();

  if (!url.endsWith('/')) {
    url += '/';
  }

  return url + newUrl.search;
};

export const isObject = (obj: any): obj is object => typeof obj === 'object' && obj !== null;

const jsonHeaders: [key: string, content: string] = ['Content-Type', 'application/json'];

export interface RequestConfig extends RequestInit {
  timeout?: number;
  rawResponse?: boolean;
}

export interface JsonRequestConfig<B> extends Omit<RequestConfig, 'body'> {
  body?: B;
}

export interface FormDataRequestConfig extends Omit<RequestConfig, 'body'> {
  body: FormData;
}

export const extendHeaders = (headers: RequestConfig['headers'], headerToSet: [key: string, value: any]) => {
  if (headers instanceof Headers) {
    headers = new Headers(headers);

    headers.append(...headerToSet);
  } else if (Array.isArray(headers)) {
    headers = [...headers, headerToSet];
  } else {
    headers = {
      ...headers,
      [headerToSet[0]]: headerToSet[1],
    };
  }

  return headers;
};

export const isGetMethod = (method: RequestConfig['method']) => !method || method === 'GET';

export const jsonSerializer = <B>({ body, ...init }: JsonRequestConfig<B> = {}) => {
  if (!isGetMethod(init.method)) {
    init.headers = extendHeaders(init.headers, jsonHeaders);
  }

  return {
    ...init,
    body: Array.isArray(body) || isObject(body) ? JSON.stringify(decamelizeKeys(body)) : undefined,
  };
};

export const formDataSerializer = ({ body, ...init }: FormDataRequestConfig) => {
  const copiedData = new FormData();

  body.forEach((value, key) => {
    copiedData.append(decamelize(key), value);
  });

  return {
    ...init,
    body: copiedData,
  };
};

export const isFormDataRequest = <B>(
  config: JsonRequestConfig<B> | FormDataRequestConfig
): config is FormDataRequestConfig => checkWindow() && config.body instanceof FormData;

export const serializeFormData = (body: object) =>
  Object.entries(body).reduce((formData, [key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((nestedValue) => {
        formData.append(decamelize(key), nestedValue);
      });
    } else {
      formData.append(decamelize(key), value);
    }

    return formData;
  }, new FormData());
