import React from 'react';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';

const Loader = ({ dark, big }) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
      `}
    >
      <div
        css={css`
          display: inline-block;
          background-color: ${dark ? theme.secondary : '#fff'};
          margin: ${big ? '0 5px' : '0 3px'};
          width: ${big ? '24px' : '10px'};
          height: ${big ? '24px' : '10px'};
          border-radius: 100%;

          animation: firstAnimation 1.5s infinite linear;

          @keyframes firstAnimation {
            0% {
              transform: scale(0.5);
            }
            25% {
              transform: scale(1);
            }
            50% {
              transform: scale(0.5);
            }
            75% {
              transform: scale(0.5);
            }
            100% {
              transform: scale(0.5);
            }
          }
        `}
      />
      <div
        css={css`
          display: inline-block;
          background-color: ${dark ? theme.secondary : '#fff'};
          margin: ${big ? '0 5px' : '0 3px'};
          width: ${big ? '24px' : '10px'};
          height: ${big ? '24px' : '10px'};
          border-radius: 100%;

          animation: secondAnimation 1.5s infinite linear;

          @keyframes secondAnimation {
            0% {
              transform: scale(0.5);
            }
            25% {
              transform: scale(0.5);
            }
            50% {
              transform: scale(1);
            }
            75% {
              transform: scale(0.5);
            }
            100% {
              transform: scale(0.5);
            }
          }
        `}
      />
      <div
        css={css`
          display: inline-block;
          background-color: ${dark ? theme.secondary : '#fff'};
          margin: ${big ? '0 5px' : '0 3px'};
          width: ${big ? '24px' : '10px'};
          height: ${big ? '24px' : '10px'};
          border-radius: 100%;

          animation: thirdAnimation 1.5s infinite linear;

          @keyframes thirdAnimation {
            0% {
              transform: scale(0.5);
            }
            25% {
              transform: scale(0.5);
            }
            50% {
              transform: scale(0.5);
            }
            75% {
              transform: scale(1);
            }
            100% {
              transform: scale(0.5);
            }
          }
        `}
      />
    </div>
  );
};

Loader.propTypes = {
  dark: PropTypes.bool,
  big: PropTypes.bool,
};

export default React.memo(Loader);
