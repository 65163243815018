import React from 'react';

import * as Styled from './styles';

interface Props {
  children: React.ReactNode;
}

const DropdownMenu = ({ children }: Props) => (
  <Styled.DropdownMenu className="dropdown-menu">
    <Styled.DropdownList
      onClick={(e) => {
        if (e.target && (e.target as HTMLElement).tagName === 'A') {
          (e.target as HTMLAnchorElement).blur();
        }
      }}
    >
      {children}
    </Styled.DropdownList>
  </Styled.DropdownMenu>
);

export default React.memo(DropdownMenu);
