import styled from '@emotion/styled';

import LayoutContainer from 'components/LayoutContainer';
import Text from 'components/Text';
import { respondFrom, respondTo } from 'styles';

export const Nav = styled.nav`
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.surface.primary};
`;

export const Header = styled(LayoutContainer)`
  padding: 24px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${respondTo.tablet} {
    padding: 16px 12px;
  }
`;

export const Logo = styled.a`
  width: 160px;
  height: 56px;
  position: relative;

  ${respondTo.mobile} {
    width: 140px;
    height: 32px;
  }
`;

export const List = styled.ul`
  display: flex;
  justify-content: flex-end;
  color: ${(props) => props.theme.foreground.secondary};
  align-items: center;

  & > li {
    display: flex;
    align-items: center;

    & > a {
      text-transform: uppercase;
      color: ${(props) => props.theme.foreground.secondary};
      font-weight: 500;
    }
  }

  & > li:last-of-type {
    & > a {
      color: ${(props) => props.theme.brand.secondary};
    }
  }

  ${respondTo.tablet} {
    display: none;
  }
`;

export const DropdownLi = styled.li`
  position: relative;

  &:hover .dropdown-menu {
    opacity: 1;
    height: auto;
  }
`;

export const Divider = styled.div`
  margin: 0 24px;
  width: 1px;
  height: 18px;
  background-color: ${(props) => props.theme.foreground.secondary};
`;

export const DrawerButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -16px -12px -16px 0;
  padding: 34px;
  background-color: transparent;

  ${respondFrom.tablet} {
    display: none;
  }

  ${respondTo.mobile} {
    padding: 22px;
  }
`;

export const DropdownLink = styled(Text)`
  padding: 10px 12px;
  text-align: left;
  display: block;
  color: ${(props) => props.theme.foreground.secondary};
  text-transform: uppercase;
  color: ${(props) => props.theme.foreground.secondary};
  font-weight: 500;

  :hover,
  :focus {
    background-color: ${(props) => props.theme.surface.secondary};
  }
`;
