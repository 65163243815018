import styled from '@emotion/styled';

export const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  height: 0;
  transition: opacity 0.2s ease-out 0.2s;
  box-shadow: 0px 10px 20px rgba(134, 143, 195, 0.15);
  overflow: hidden;

  &:focus-within {
    opacity: 1;
    height: auto;
  }
`;

export const DropdownList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  border-radius: 8px;
  background-color: ${(props) => props.theme.surface.primary};
  padding: 6px 0;
  border: 1px solid ${(props) => props.theme.surface.tertiary};
  margin-top: 8px;
  min-width: 220px;
`;
