import styled from '@emotion/styled';

import LayoutContainer from 'components/LayoutContainer';
import { respondFrom, respondTo } from 'styles';

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.brand.secondary};
`;

export const Layout = styled(LayoutContainer)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 64px 16px;

  ${respondTo.tablet} {
    padding: 40px 16px;
  }
`;

export const List = styled.ul`
  display: flex;
  align-items: center;

  & > li {
    display: flex;
    align-items: center;
  }

  a {
    cursor: pointer;
    text-transform: uppercase;
    color: ${(props) => props.theme.surface.primary};
    font-weight: 500;
    text-align: center;
  }

  ${respondTo.tablet} {
    flex-direction: column;

    & > li {
      flex-direction: column;
    }
  }
`;

export const Divider = styled.div`
  margin: 0 24px;
  width: 1px;
  height: 18px;
  background-color: ${(props) => props.theme.surface.primary};

  ${respondTo.tablet} {
    width: 0;
    height: 0;
    margin: 10px 0;
    background-color: transparent;
  }
`;

export const ExtraDivider = styled.div`
  width: 1px;
  height: 18px;
  background-color: ${(props) => props.theme.surface.primary};
  margin: 0 12px;

  ${respondTo.tablet} {
    width: 0;
    background-color: transparent;
    margin: 5px 0;
  }
`;

export const ExtraLinks = styled.div`
  margin-top: 44px;
  padding-top: 48px;
  border-top: 1px solid ${(props) => props.theme.surface.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${respondTo.tablet} {
    border-top: none;
    flex-direction: column-reverse;
    margin-top: 16px;
    padding-top: 0;
  }
`;

export const ExtraCenter = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.surface.primary};
  font-size: 18px;
  text-align: center;

  a {
    color: ${(props) => props.theme.surface.primary};
    text-decoration: underline;
    text-align: center;
  }

  ${respondTo.tablet} {
    margin-top: 32px;
    flex-direction: column;
  }
`;

export const SocialsLinks = styled.ul`
  display: flex;
  flex-shrink: 0;

  & > li:not(:last-child) {
    margin-right: 8px;
  }

  ${respondTo.tablet} {
    margin-top: 20px;
    flex-basis: 40px;
  }

  a {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1.5px solid ${(props) => props.theme.surface.primary};

    ${respondTo.tablet} {
      width: 36px;
      height: 36px;
    }
  }
`;

export const DesktopLogo = styled.div`
  width: 156px;
  height: 100px;
  position: relative;

  ${respondTo.tablet} {
    display: none;
  }
`;

export const MobileLogoWrapper = styled.div`
  margin-bottom: 28px;
  padding-bottom: 32px;
  border-bottom: 1px solid ${(props) => props.theme.surface.primary};
  display: flex;
  width: 100%;
  max-width: 220px;
  align-self: center;
  justify-content: center;

  ${respondFrom.tablet} {
    display: none;
  }
`;

export const MobileLogo = styled.div`
  width: 80px;
  height: 56px;
  position: relative;
`;
