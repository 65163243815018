const theme = {
  gradient: {
    light:
      'linear-gradient(116.33deg, rgba(242, 245, 253, 0.7) 16.42%, rgba(254, 254, 255, 0.0520833) 27.78%, rgba(0, 247, 230, 0.1) 89.15%)',
    dark: 'linear-gradient(157.61deg, #2D3672 16.38%, #09091A 64.15%)',
  },
  primary: '#69CADF',
  accent: '#ff004b',
  secondary: '#545e87',
  lightPurple: '#d8ddf0',
  lightGray: '#f5faff',
  dark: '#01010a',
  darkBlue: '#131734',
  backgroundDark: '#09091A',
  accentBright: '#58DB7D',
  highlight: '#FFE85F',
  status: {
    success: '#4be1ab',
    error: '#ff004b',
  },
  // new theme
  fonts: {
    openSans:
      'Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
  },
  surface: {
    primary: '#fff',
    secondary: '#F2F2F5',
    tertiary: '#D8D9E2',
  },
  foreground: {
    primary: '#161D3E',
    secondary: '#222B57',
    tertiary: '#34407D',
    quaternary: '#989FBC',
  },
  brand: {
    primary: '#2FE3EF',
    primaryBright: '#D7F7F9',
    primaryDark: '#B1F0F4',
    secondary: '#F05D4F',
    secondaryBright: '#F9D7D4',
    secondaryDark: '#F4B9B3',
    tertiary: '#C7E832',
    tertiaryBright: '#EFF5D6',
    tertiaryAccent: '#E2EFA7',
    quaternary: '#9747FF',
    quaternaryBright: '#EADCFC',
    quaternaryAccent: '#D2B3FB',
  },
  bg: {
    light: '#FFFFFF',
    lightOverlay: 'rgba(255, 255, 255, 0.9)',
    content: '#F2F2F5',
    contentOverlay: '#F2F2F5',
    controls: '#989FBC',
    dark: '#222B57',
    darkOverlay: '#161D3E',
  },
  semantic: {
    success: '#16E198',
    error: '#FC3C1D',
  },
  ui: {
    primary: '#F05D4F',
    secondary: '#2FE3EF',
    accent: '#C7E832',
    neutral: '#D8D9E2',
    light: '#FFFFFF',
    dark: '#161D3E',
    info: '#000000',
    success: '#16E198',
    danger: '#FC3C1D',
    warning: '#FDB118',
    highlight: '#FFE85F',
  },
  shadow: {
    button2: '0px 20px 40px rgba(59, 74, 119, 0.49)',
  },
} as const;

export default theme;
