import { css } from '@emotion/react';
import styled from '@emotion/styled';

import theme from 'styles/theme';
import typography from 'styles/typography';

export const BaseAnchor = styled.a<{ $icon?: any }>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: ${(props) => (props.$icon ? `url(${props.$icon.src})` : 'none')};
`;

export const BaseButton = styled.button<{ $icon?: any }>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: ${(props) => (props.$icon ? `url(${props.$icon.src})` : 'none')};

  :disabled {
    cursor: pointer;
  }
`;

const BrightnessHover = css`
  :hover:not(:disabled) {
    filter: brightness(0.97);
  }
  :active:not(:disabled) {
    filter: brightness(0.95);
  }
`;

const OpacityHover = css`
  :hover:not(:disabled) {
    opacity: 0.95;
  }
  :active:not(:disabled) {
    opacity: 0.9;
  }
`;

export const BigButton = css`
  ${typography.buttonBig}
  padding: 0 20px;
  min-height: 76px;
  border-radius: 5px;
  background-position: right 12px center;
  background-size: 36px 36px;
`;

export const MediumButton = css`
  ${typography.buttonMedium}
  padding: 0 16px;
  min-height: 56px;
  border-radius: 5px;
  background-position: right 8px center;
  background-size: 32px 32px;
`;

export const SmallButton = css`
  ${typography.buttonSmall}
  padding: 0 12px;
  min-height: 48px;
  border-radius: 3px;
  background-position: right 4px center;
  background-size: 28px 28px;
`;

export const PrimaryButton = css`
  ${BrightnessHover};
  background-color: ${theme.brand.secondary};
  color: ${theme.ui.light};
`;

export const SecondaryButton = css`
  ${BrightnessHover};
  background-color: ${theme.brand.primary};
  color: ${theme.foreground.primary};
  border: 2px solid ${theme.brand.primary};
`;

export const TertiaryButton = css`
  ${BrightnessHover};
  background-color: ${theme.brand.tertiary};
  color: ${theme.foreground.primary};
`;

export const QuaternaryButton = css`
  ${OpacityHover}
  background-color: ${theme.foreground.primary};
  color: ${theme.ui.light};
`;

export const BorderedButton = css`
  ${BrightnessHover}
  background-color: ${theme.surface.primary};
  color: ${theme.foreground.primary};
  border: 2px solid ${theme.foreground.primary};
`;

export const NulledButton = css`
  color: ${theme.foreground.primary};
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
`;

export const LoadingButton = css`
  cursor: wait;

  :disabled {
    cursor: wait;
  }
`;

export const DisabledButton = css`
  background-color: ${theme.surface.tertiary};
  color: ${theme.foreground.quaternary};
  cursor: not-allowed;

  :disabled {
    cursor: not-allowed;
  }
`;
