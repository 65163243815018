import { Control, Path, UseFormSetError } from 'react-hook-form';

import { FIELD_ERROR, FORM_ERROR } from 'lib/forms';
import createFormError from 'lib/forms/createFormError';
import { ApiError, FormError, IFulfilledResult, IRejectedResult, IResult } from 'types';

export class Result<V> implements IResult<V> {
  payload: V;
  status: number;
  swrKey: string;

  constructor({ payload, status, swrKey }: IResult<V>) {
    this.payload = payload;
    this.status = status;
    this.swrKey = swrKey;
  }
}

export class FulfilledResult<R> extends Result<R> implements IFulfilledResult<R> {}

export class RejectedResult<E extends ApiError = ApiError> extends Result<E> implements IRejectedResult<E> {
  error: true = true;

  toFormError() {
    return createFormError(this);
  }

  toString() {
    return Object.values(this.toFormError()).join(' ');
  }

  mapFormErrors<TFieldValues extends FormError>(control: Control<any>, setError: UseFormSetError<TFieldValues>) {
    Object.entries(this.toFormError()).forEach(([key, value]) => {
      /**
       * By default react-hook-form blocks the submit button if there are any registered errors.
       * Code checks for exisiting fields or global form error to mark the field as error in order
       * to prevent the unexpected blocking submit button.
       */
      if (key === FORM_ERROR || control._fields[key]) {
        setError(key as Path<TFieldValues>, {
          type: FIELD_ERROR,
          message: value,
        });
      }
    });
  }
}
