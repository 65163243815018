import React from 'react';

import { Nav, NavProps } from '../Nav';

const menuItems: NavProps['menuItems'] = [
  ['Parity Home', 'https://paritynow.co', true],
  ['Orders', '/orders', false],
];

const dropdownItems: NavProps['dropdownItems'] = [
  ['Settings', '/settings'],
  ['Log Out', '/signout'],
];

const drawerItems: NavProps['drawerItems'] = [['Parity Home', 'https://paritynow.co', true]];

const userDrawerItems: NavProps['userDrawerItems'] = [
  ['Dashboard', '/dashboard', false],
  ['Orders', '/orders', false],
  ['Settings', '/settings', false],
];

export const NavBrands = React.memo(() => {
  return (
    <Nav
      menuItems={menuItems}
      dropdownItems={dropdownItems}
      drawerItems={drawerItems}
      userDrawerItems={userDrawerItems}
    />
  );
});
