import capitalize from 'capitalize';

import { matchApiError } from 'lib/request';
import { ApiError, DjangoApiError, IRejectedResult } from 'types';

import { FORM_ERROR } from '.';

const concatArrayError = (errors: string[]) => errors.map((error) => capitalize(error)).join(' ');

export default function createFormError<E extends ApiError = ApiError>(
  result: IRejectedResult<E>
): { [key: string]: string } {
  if (result.payload && matchApiError(result.payload)) {
    const { status } = result;
    const payload = result.payload as DjangoApiError;

    if (status === -1) {
      return {
        [FORM_ERROR]: 'Connection timeout. Please, check your Internet connection.',
      };
    }

    if (status === 404) {
      return {
        [FORM_ERROR]: payload.detail || 'Not found.',
      };
    }

    if (status === 401) {
      return {
        [FORM_ERROR]: payload.detail || 'Unauthorized.',
      };
    }

    if (status === 403) {
      return {
        [FORM_ERROR]: payload.detail || 'Forbidden.',
      };
    }

    if (status === 400 && typeof payload === 'object') {
      return Object.entries(payload).reduce((pv, [key, value]) => {
        if (!value) {
          return pv;
        }

        if (typeof value === 'string') {
          return value;
        }

        return {
          ...pv,
          [key === 'nonFieldErrors' ? FORM_ERROR : key]: concatArrayError(value),
        };
      }, {});
    }

    if (status >= 400) {
      return {
        [FORM_ERROR]: 'Internal server error. Please, try again later.',
      };
    }
  }

  return {
    [FORM_ERROR]: 'Unknown error has occurred',
  };
}
