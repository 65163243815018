import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';

import { checkWindow } from 'lib/browser';

const defaultState = {
  height: 900,
  width: 900,
};

const ResizeContext = React.createContext(defaultState);

const Provider = ({ children }) => {
  const [state, setState] = React.useState(
    checkWindow()
      ? {
          width: window.innerWidth,
          height: window.innerHeight,
        }
      : defaultState
  );

  const setNewDimensions = () => {
    setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  const throttledSetNewWidth = React.useRef(throttle(setNewDimensions, 200)).current;

  React.useEffect(() => {
    setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    window.addEventListener('resize', throttledSetNewWidth);

    return () => {
      window.removeEventListener('resize', throttledSetNewWidth);
    };
  }, []);

  return <ResizeContext.Provider value={state}>{children}</ResizeContext.Provider>;
};

Provider.propTypes = {
  children: PropTypes.node,
};

export default Provider;
export { ResizeContext };
