import styled from '@emotion/styled';

import Text from 'components/Text';
import { DESKTOP, respondFrom, respondTo } from 'styles';

export const Container = styled.aside`
  position: fixed;
  left: calc((100vw - ${DESKTOP}px) / 2);
  right: calc((100vw - ${DESKTOP}px) / 2);
  bottom: 16px;
  z-index: 103;
  padding: 24px 16px;
  display: flex;
  animation-name: appear, appear;
  animation-fill-mode: forwards;
  animation-delay: 0s, 15s;
  animation-duration: 0.35s;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-direction: normal, reverse;
  z-index: 111;
  align-items: center;

  ${respondFrom.tablet} {
    border-radius: 5px;
  }

  ${respondTo.desktop} {
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  flex: 1;
`;

export const Title = styled(Text)`
  margin-bottom: 4px;
  color: ${(props) => props.theme.surface.primary};
`;

export const Description = styled(Text)`
  color: ${(props) => props.theme.surface.primary};
`;
