import styled from '@emotion/styled';

import { sizes } from 'styles';

export default styled.div`
  max-width: ${sizes.layoutMaxWidth};
  margin: 0 auto;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
