export enum AuthRole {
  All = 1,
  Anonymous,
  Authenticated,
  EmailVerified,
  Onboarded,
}

export enum UaDevice {
  Mobile = 1,
  Tablet,
  Desktop,
}

export enum OnboardingStep {
  First = 1,
  Second = 2,
  Third = 3,
  Fourth = 4,
  Finished = 5,
}

export enum AccessType {
  Pending = 1,
  Pro = 2,
  Community = 3,
  Resource = 4,
  ProPlus = 6,
}

// Legacy
export const ACCESS_TYPE_NAME = {
  [AccessType.Pending]: 'Pending',
  [AccessType.Pro]: 'Pro',
  [AccessType.ProPlus]: 'Pro+',
  [AccessType.Community]: 'Community',
  [AccessType.Resource]: 'Resource',
} as const;

export enum AssessmentStatus {
  NotStarted = 1,
  InProgress = 2,
  Pending = 3,
  Approved = 4,
  Error = 5,
}

export enum AssessmentType {
  NFT = 1,
  Step = 2,
}

/*
Legacy
*/
export enum ConnectBy {
  Call = 1,
  Text = 2,
  Email = 3,
}

export enum BasketballDescription {
  CurrentWNBA = 1,
  FormerWNBA,
  WNBATrainingCampOnly,
  ProLeagueNotWNBA,
}

export const BASKETBALL_DESCRIPTION_NAME = {
  [BasketballDescription.CurrentWNBA]: 'Current WNBA',
  [BasketballDescription.FormerWNBA]: 'Former WNBA',
  [BasketballDescription.WNBATrainingCampOnly]: 'WNBA Training Camp Only',
  [BasketballDescription.ProLeagueNotWNBA]: 'Pro league, not WNBA',
} as const;

export enum Gender {
  Woman = 1,
  NonBinary,
  Agender,
  Man,
  Other,
  PreferNotToSay,
}

export const GENDER_NAME = {
  [Gender.Woman]: 'Woman',
  [Gender.NonBinary]: 'Non-Binary',
  [Gender.Agender]: 'Agender',
  [Gender.Man]: 'Man',
  [Gender.Other]: 'Other',
  [Gender.PreferNotToSay]: 'Prefer not to say',
} as const;

export enum Pronouns {
  She = 1,
  They,
  Ze,
  He,
  PreferNotToSay,
  Other,
}

export const PRONOUNS_NAME = {
  [Pronouns.She]: 'She/Her/Hers',
  [Pronouns.They]: 'They/Them/Theirs',
  [Pronouns.Ze]: 'Ze/Zem/Zeirs',
  [Pronouns.He]: 'He/Him/His',
  [Pronouns.PreferNotToSay]: 'Prefer not to say',
  [Pronouns.Other]: 'Other',
} as const;

export enum SexualOrientation {
  Straight = 1,
  Bisexual,
  Gay,
  Lesbian,
  Queer,
  Asexual,
  Other,
  PreferNotToSay,
}
