import React from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';

import Logo from 'assets/nav/logo-text.svg';
import MenuBurger from 'assets/nav/menu-burger.svg';
import useUser from 'data/useUser';

import DropdownMenu from './components/DropdownMenu';

const DrawerMenu = dynamic(() => import('./components/DrawerMenu').then((m) => m.DrawerMenu), {
  ssr: false,
});
import * as Styled from './styles';

const defaultMenuItems: NavProps['menuItems'] = [
  ['Brands', 'https://paritynow.co/brands/', true],
  ['Athletes', 'https://paritynow.co/athletes/', true],
  //['NFTs', 'https://www.market.paritynow.co/', true],
  ['Parity Fans', 'https://paritynow.co/parity-fans/', true],
  ['About Us', 'https://paritynow.co/about-us/', true],
  ['Impact', 'https://impact.paritynow.co/', true],
];

const defaultDropdownItems: NavProps['dropdownItems'] = [
  ['Webinars', '/webinars'],
  ['Update Profile', '/set-up/survey/start?recurring=true'],
  ['Settings', '/settings'],
  ['Log Out', '/signout'],
];

export interface NavProps {
  menuItems?: [title: string, url: string, anchor?: boolean][];
  dropdownItems?: [title: string, url: string][];
  drawerItems?: [title: string, url: string, anchor?: boolean][];
  userDrawerItems?: [title: string, url: string, anchor?: boolean][];
}

export const Nav = React.memo(
  ({ menuItems = defaultMenuItems, dropdownItems = defaultDropdownItems, drawerItems, userDrawerItems }: NavProps) => {
    const { user } = useUser({
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: false,
      revalidateOnReconnect: false,
    });

    const [drawerActive, setDrawerActive] = React.useState(false);

    return (
      <Styled.Nav>
        <Styled.Header as="header">
          <Link href="/" passHref>
            <Styled.Logo>
              <Image alt="Parity" src={Logo} layout="fill" />
            </Styled.Logo>
          </Link>
          <Styled.List>
            {menuItems.map((item, index) =>
              item[2] ? (
                <li key={`li-${index}`}>
                  <a href={item[1]} target="_blank" rel="noopener noreferrer">
                    {item[0]}
                  </a>
                  <Styled.Divider />
                </li>
              ) : (
                <li key={`li-${index}`}>
                  <Link href={item[1] as string}>{item[0]}</Link>
                  <Styled.Divider />
                </li>
              )
            )}
            {user ? (
              <Styled.DropdownLi>
                <Link href="/dashboard">Dashboard</Link>
                <DropdownMenu>
                  {dropdownItems.map((item, index) => (
                    <li key={`li-${index}`}>
                      <Link href={item[1]} passHref>
                        <Styled.DropdownLink as="a">{item[0]}</Styled.DropdownLink>
                      </Link>
                    </li>
                  ))}
                </DropdownMenu>
              </Styled.DropdownLi>
            ) : (
              <li>
                <Link href="/signin">Sign In</Link>
              </li>
            )}
          </Styled.List>

          <Styled.DrawerButton type="button" onClick={() => setDrawerActive(!drawerActive)}>
            <Image alt="Menu" src={MenuBurger} width={20} height={20} />
          </Styled.DrawerButton>
          <DrawerMenu
            isActive={drawerActive}
            close={() => setDrawerActive(false)}
            user={user}
            drawerItems={drawerItems}
            userItems={userDrawerItems}
          />
        </Styled.Header>
      </Styled.Nav>
    );
  }
);
