import React from 'react';

import Loader from 'components/Loader';

import {
  BaseAnchor,
  BaseButton,
  BigButton,
  BorderedButton,
  DisabledButton,
  LoadingButton,
  MediumButton,
  NulledButton,
  PrimaryButton,
  QuaternaryButton,
  SecondaryButton,
  SmallButton,
  TertiaryButton,
} from './styles';

export const ButtonVariant = ['nulled', 'primary', 'secondary', 'tertiary', 'quaternary', 'bordered'] as const;
export const ButtonSize = ['small', 'medium', 'big'] as const;

interface ButtonStylingProps {
  variant?: typeof ButtonVariant[number];
  size?: typeof ButtonSize[number];
  icon?: any;
}

interface SharedButtonProps {
  children?: React.ReactNode;
}

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, SharedButtonProps {
  as?: 'button';
  loading?: boolean;
  resolve?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

interface AnchorProps extends React.AnchorHTMLAttributes<HTMLAnchorElement>, SharedButtonProps {
  openNewTab?: boolean;
  as?: 'a';
}

type BaseButtonProps = ButtonProps | AnchorProps;

export const isAnchor = (props: BaseButtonProps): props is AnchorProps => props.as === 'a';

const Button = React.memo((props: BaseButtonProps & ButtonStylingProps & { innerRef?: any }) => {
  const [localLoading, setLocalLoading] = React.useState(false);

  if (isAnchor(props)) {
    const { as, openNewTab, children, icon, variant = 'nulled', size = 'big', innerRef, ...otherProps } = props;

    const openNewTabProps = openNewTab
      ? {
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      : undefined;

    return (
      <BaseAnchor
        {...otherProps}
        {...openNewTabProps}
        ref={innerRef}
        $icon={icon}
        css={[
          BaseButton,
          variant === 'primary' && PrimaryButton,
          variant === 'secondary' && SecondaryButton,
          variant === 'tertiary' && TertiaryButton,
          variant === 'quaternary' && QuaternaryButton,
          variant === 'bordered' && BorderedButton,
          variant === 'nulled' && NulledButton,
          size === 'big' && BigButton,
          size === 'medium' && MediumButton,
          size === 'small' && SmallButton,
        ]}
      >
        {children}
      </BaseAnchor>
    );
  }

  const {
    as,
    children,
    icon,
    disabled,
    loading,
    resolve,
    onClick,
    innerRef,
    variant = 'nulled',
    size = 'big',
    type = 'button',
    ...otherProps
  } = props;

  return (
    <BaseButton
      {...otherProps}
      ref={innerRef}
      $icon={!(localLoading || loading) && icon ? icon : undefined}
      disabled={disabled || localLoading || loading}
      type={type}
      onClick={async (event) => {
        if (resolve) {
          try {
            setLocalLoading(true);
            await resolve(event);
            setLocalLoading(false);
          } catch (e) {
            setLocalLoading(false);
          }
        } else if (onClick) {
          onClick(event);
        }
      }}
      css={[
        BaseButton,
        variant === 'primary' && PrimaryButton,
        variant === 'secondary' && SecondaryButton,
        variant === 'tertiary' && TertiaryButton,
        variant === 'quaternary' && QuaternaryButton,
        variant === 'bordered' && BorderedButton,
        variant === 'nulled' && NulledButton,
        size === 'big' && BigButton,
        size === 'medium' && MediumButton,
        size === 'small' && SmallButton,
        (localLoading || loading) && LoadingButton,
        disabled && DisabledButton,
      ]}
    >
      {localLoading || loading ? <Loader dark={!['quaternary', 'primary'].includes(variant)} /> : children}
    </BaseButton>
  );
});

export default React.forwardRef<HTMLAnchorElement | HTMLButtonElement, BaseButtonProps & ButtonStylingProps>(
  (props, ref) => <Button {...props} innerRef={ref} />
);
