import { css } from '@emotion/react';

import { respondTo } from 'styles';

const typography = {
  jumbo1: css`
    font-style: normal;
    font-weight: 800;
    font-size: 80px;
    line-height: 88px;
    text-transform: uppercase;

    ${respondTo.mobile} {
      font-size: 36px;
      line-height: 48px;
    }
  `,
  jumbo2: css`
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 80px;
    text-transform: uppercase;

    ${respondTo.mobile} {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
    }
  `,
  h1: css`
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 54px;

    ${respondTo.mobile} {
      font-size: 32px;
      line-height: 40px;
    }
  `,
  h2: css`
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;

    ${respondTo.mobile} {
      font-size: 24px;
      line-height: 30px;
    }
  `,
  h3: css`
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 42px;

    ${respondTo.mobile} {
      font-size: 20px;
      line-height: 25px;
    }
  `,
  h4: css`
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;

    ${respondTo.mobile} {
      font-size: 18px;
      line-height: 22px;
    }
  `,
  h5: css`
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;

    ${respondTo.mobile} {
      font-size: 16px;
      line-height: 20px;
    }
  `,
  subTitle1: css`
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;

    ${respondTo.mobile} {
      font-size: 20px;
      line-height: 30px;
    }
  `,
  body1: css`
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;

    ${respondTo.mobile} {
      font-size: 16px;
      line-height: 28px;
    }
  `,
  body2: css`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;

    ${respondTo.mobile} {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
    }
  `,
  buttonBig: css`
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
  `,
  buttonMedium: css`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    text-transform: uppercase;
  `,
  buttonSmall: css`
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
  `,
  nav: css`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-transform: uppercase;
  `,
  caption: css`
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  `,
  label: css`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  `,
};

export default typography;
