import React from 'react';
import type { ImageProps } from 'next/image';
import Image from 'next/image';

import * as Styled from './styles';

export interface IconProps extends ImageProps {
  className?: string;
  alt: string;
  size?: number;
}

const Icon = ({ className, alt, size = 24, layout = 'responsive', ...props }: IconProps) => (
  <Styled.Container className={className} size={size}>
    <Image {...props} alt={alt} width={size} height={size} layout={layout} />
  </Styled.Container>
);

export default React.memo(Icon);
